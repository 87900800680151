var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "no-order-item-component" } },
    [_c("slide-heading", { attrs: { "html-heading": _vm.heading } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }