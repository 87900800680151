<template>
  <div id="no-order-item-component">
    <slide-heading :html-heading="heading" />
  </div>
</template>

<script>
export default {
  name: 'NoOrderItem',
  components: {
    SlideHeading: () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  computed: {
    heading() {
      return `It doesn't look like you have an active filing yet.`
    },
  },
}
</script>
